<template>
  <v-container id="Vuelos" class="mx-auto">
    <h1>Vuelos</h1>
    <h3 v-if="proximosVuelos.length">
      Seleccionamos estas ofertas para ti, con precios sorprendentes.
    </h3>
    <h3 v-else>En estos momentos no tenemos vuelos registrados</h3>
    <v-row v-if="vuelos.length === 0">
      <v-col v-for="n of 6" :key="n" cols="12" sm="6" md="4">
        <v-skeleton-loader
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="vuelo in proximosVuelos"
        :key="vuelo.id"
      >
        <router-link
          :to="{
            name: 'Detalle',
            params: { tipoDeViaje: 'Vuelos', id: vuelo.id },
          }"
        >
          <CardProximoVuelo :infoVuelo="vuelo" />
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardProximoVuelo from "@/components/Cards/CardProximoVuelo";
import { mapGetters, mapState } from "vuex";
export default {
  name: "Vuelos",
  components: {
    CardProximoVuelo,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("Vuelos", ["vuelos"]),
    ...mapGetters("Vuelos", ["proximosVuelos"]),
  },
  methods: {},
  title() {
    return `Vuelos - HummingBird`;
  },
};
</script>

<style lang="scss" scoped>
#Vuelos {
  padding-top: 150px;
  padding-bottom: 85px;
}

@media (min-width: 960px) {
  #Vuelos {
    h1 {
      font-size: 32px;
    }
    h3 {
      font-size: 20px;
    }
  }
}
</style>
